// background
@include bg(green, white);
@include bg(red, white);
@include bg('gradient-1', white);
@include bg('gradient-2', white);
@include bg('gradient-1-inverted', white);
@include bg('gradient-3', var(--dark-100));
@include bg('gradient-4', var(--dark-100));
@include bg('gradient-5', white);
@include bg('gradient-black-white', white);
@include bg('gradient-logo', white);
@include bg('dark-100', white);
@include bg('dark-75', white);
@include bg('gradient-blue-purple', white);
@include bg('gradient-blue-blue', white);
@include bg('gradient-blue-blue-h', white);
@include bg('gradient-blue-blue-inverse', white);
@include bg('white-translucent-1', white);
.has-bg {
  &-transparent {
    background: transparent !important;
  }
}
