.ProfileIndicator {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: absolute;
  bottom: 15px;
  left: -6px;
  z-index: 2;
}
