@import '../../styles/responsive';

.Profile {
  @include desktop() {
    &-container {
      aside {
        order: 2;
      }
    }
    &-quote-form {
      background: var(--gradient-1);
      padding: 1rem;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &-sidebar {
      max-height: calc(100vh - 90px - 1rem);
      &.is-offered {
        max-height: calc(100vh - 90px - 2rem);
        top: calc(60px + 44px);
      }
      position: sticky;
      top: 60px;
      z-index: 3;
    }
    &-details {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto auto;
      grid-template-areas: 'left right';
    }
    &-info {
      grid-area: left;
    }
    &-bio {
      grid-area: right;
    }
    &-kit {
      @extend .Profile-bio;
    }
  }
  &-alert {
    &.is-sticky {
      position: sticky;
      top: 44px;
      z-index: 3;
    }
  }
}
