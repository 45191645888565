// form
.input,
.textarea,
.select select {
  @include input-custom();
  &[readOnly] {
    border: 0;
  }
  &.is-small {
    width: 3.5em;
  }
  &::placeholder {
    font-size: 0.8em;
  }
}
.select select {
  &:invalid {
    color: var(--dark-25);
  }
}

.has-error {
  .input,
  .textarea,
  .select select {
    border-color: var(--red);
  }
}
