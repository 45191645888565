// font, text
.has-text {
  &-primary {
    font-family: var(--font-primary);
  }
  &-secondary {
    font-family: var(--font-secondary);
  }
  &-green {
    color: var(--green);
  }
  &-white {
    color: white;
  }
  &-purple {
    color: var(--purple);
  }
  &-blue {
    color: var(--blue);
  }
  &-cyan {
    color: var(--cyan);
  }
  &-dark {
    &-100 {
      color: var(--dark-100);
    }
    &-75 {
      color: var(--dark-75);
    }
    &-50 {
      color: var(--dark-50);
    }
    &-25 {
      color: var(--dark-25);
    }
  }
  &-gradient {
    &-1 {
      @include gradient-font(var(--gradient-text-1), var(--green));
      &-inverted {
        @include gradient-font(var(--gradient-text-1-inverted), var(--green));
      }
    }
    &-2 {
      @include gradient-font(var(--gradient-text-2), var(--green));
    }
    &-logo {
      @include gradient-font(var(--gradient-text-logo), var(--cyan));
      &-inverted {
        @include gradient-font(var(--gradient-text-logo-inverted), var(--cyan));
      }
    }
    &-blue-blue {
      @include gradient-font(var(--gradient-text-blue-blue), var(--cyan));
      &-inverted {
        @include gradient-font(
          var(--gradient-text-blue-blue-inverted),
          var(--cyan)
        );
      }
    }
  }
}

.is {
  &-size {
    &-small {
      font-size: 0.875em;
    }
    &-12p {
      font-size: 12px;
    }
    &-14p {
      font-size: 14px;
    }
    &-16p {
      font-size: 16px;
    }
    &-18p {
      font-size: 18px;
    }
  }
}

.is-underlined {
  text-decoration: underline;
}

.has-text-truncated {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
