.StarRating {
  --fill: 50%;
  span {
    color: var(--purple);
    background: linear-gradient(
      to right,
      var(--purple) var(--fill),
      rgba(gray, 0.3) var(--fill)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
