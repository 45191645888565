.ProviderCard {
  &-cover {
    .image {
      overflow: hidden;
      border-radius: 4px;
    }
  }
  &-view-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: rgba(white, 0.4);
    right: 15px;
    font-size: 14px;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
  }

  // featured-sideways view
  &-featured {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 4px;
    background: var(--gradient-1);
    padding: 2px;
    .ProviderCard {
      &-provider-info {
        display: flex;
        flex-direction: column;
        padding: 0 8px 10px;
        background: white;
      }
      &-cover {
        .image {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &-list {
      border-radius: 4px;
      box-shadow: 0 0 3px 3px rgba(black, 0.05);
      .ProviderCard {
        &-provider-info {
          padding: 8px;
        }
        &-cover {
          .image {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  &-booked-out {
    opacity: 0.8;
  }
}
