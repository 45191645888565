.ProgressBar {
  width: 100%;
  &-progress {
    // background: var(--green);
    transition: width 0.3s linear;
    height: 3px;
  }
  &-animate {
    animation: animate 0.4s forwards;
  }
}

@keyframes animate {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
