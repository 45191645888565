@import '../../styles/responsive';

.HomeStudioMap {
  &-location-button {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 3;
    height: 36px;
    width: 36px;
  }

  &-provider-list {
    &-item {
      max-width: 340px;
      .ProviderCard-cover {
        padding: 6px;
      }
      &-selected {
        .ProviderCard-cover {
          background: var(--gradient-1);
          border-radius: 4px;
        }
      }
      .ProfileIndicator > div > div {
        display: none;
      }
      // @include tablet-only() {
      //   .ProfileIndicator > div > div {
      //     display: none;
      //   }
      // }
      // @include desktop-only() {
      //   .ProfileIndicator > div > div {
      //     display: none;
      //   }
      // }
      // @include desktop-only() {
      //   background: red;
      // }
    }
  }

  &-map {
    @media screen and (min-width: 2100px) {
      max-width: calc(100vw - 455px);
    }
  }
}
