@import '../../styles/responsive';

.FilterGroup {
  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }
  &-sort-select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    min-height: 36px;
    cursor: pointer;
  }
  &-item {
    &-hidden {
      display: none;
    }
  }
  @include desktop {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
  }
}
