$red: #ff5a5f;
// variables
$success: #6fcf97;
$green: #6fcf97;
$family-primary: 'Quicksand', sans-serif;
$family-secondary: 'Quicksand', sans-serif;
:root {
  --font-primary: #{$family-primary};
  --font-secondary: #{$family-secondary};

  // theme
  --primary: hsl(171, 100%, 41%);
  --black: hsl(0, 0%, 4%);
  --black-bis: hsl(0, 0%, 7%);
  --black-ter: hsl(0, 0%, 14%);
  --grey-darker: hsl(0, 0%, 21%);
  --grey-dark: hsl(0, 0%, 29%);
  --grey: hsl(0, 0%, 48%);
  --grey-light: hsl(0, 0%, 71%);
  --grey-lighter: hsl(0, 0%, 86%);
  --white-ter: hsl(0, 0%, 96%);
  --white-bis: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
  --orange: hsl(14, 100%, 53%);
  --yellow: hsl(48, 100%, 67%);
  --green: #6fcf97;
  --turquoise: hsl(171, 100%, 41%);
  --cyan: hsl(204, 86%, 53%);
  --blue: hsl(217, 71%, 53%);
  --purple: hsl(271, 100%, 71%);
  --red: hsl(348, 100%, 61%);

  --dark-5: rgba(31, 32, 65, 0.05);
  --dark-25: rgba(31, 32, 65, 0.25);
  --dark-50: rgba(31, 32, 65, 0.5);
  --dark-75: rgba(31, 32, 65, 0.75);
  --dark-100: rgb(31, 32, 65);
  --purple: #bc9cff;
  --purple-light: #bc9cff50;
  --green: #{$green};
  --red: #{$red};

  --gradient-1: linear-gradient(180deg, #bc9cff 0%, #8ba4f9 100%);
  --gradient-1-inverted: linear-gradient(to top, #bc9cff 0%, #8ba4f9 100%);
  --gradient-1-default: #bc9cff;
  --gradient-1-light: linear-gradient(180deg, #bc9cff70 0%, #8ba4f970 100%);
  --gradient-2: linear-gradient(180deg, #6fcf97 0%, #66d2ea 100%);
  --gradient-2-default: var(--green);
  --gradient-3: linear-gradient(
    180deg,
    rgba(31, 32, 65, 0.175) 0%,
    rgba(255, 255, 255, 0.35) 100%
  );
  --gradient-3-default: rgba(31, 32, 65, 0.175);

  --gradient-4: linear-gradient(180deg, #fb6283 0%, #fa7554 100%);
  --gradient-4-light: linear-gradient(180deg, #fb628386 0%, #fa75548e 100%);
  --gradient-5: linear-gradient(
    to right,
    #4facfe 0%,
    #00f2fe 100%
  ); // malibu beach
  --gradient-logo: linear-gradient(to right, #01b1ea 35%, #82318e 100%);
  --gradient-dark: linear-gradient(
    180deg,
    rgba(31, 32, 65, 0.74) 0%,
    rgba(31, 32, 65, 0.5) 100%
  );
  --gradient-blue-purple: linear-gradient(
    92.92deg,
    #50a1ff 31.4%,
    #bc9cff 50.38%
  );
  --gradient-blue-blue: linear-gradient(to top, #50a1ff 0%, #66d2ea 100%);
  --gradient-blue-blue-inverted: linear-gradient(
    to bottom,
    #50a1ff 0%,
    #66d2ea 100%
  );
  --gradient-blue-blue-h: linear-gradient(to right, #50a1ff 0%, #66d2ea 100%);

  --gradient-logo-slanted: linear-gradient(-45deg, #01b1ea 35%, #82318e 100%);
  --gradient-black-white: linear-gradient(
    to right,
    #1f2041 0%,
    rgba(31, 32, 65, 0.25) 100%
  );

  --white-translucent-1: rgba(255, 255, 255, 0.4);

  // text gradients
  --gradient-text-1: -webkit-linear-gradient(180deg, #bc9cff 0%, #8ba4f9 100%);
  --gradient-text-1-inverted: -webkit-linear-gradient(
    0deg,
    #bc9cff 0%,
    #8ba4f9 100%
  );
  --gradient-text-2: -webkit-linear-gradient(180deg, #6fcf97 0%, #66d2ea 100%);
  --gradient-text-logo: -webkit-linear-gradient(
    right,
    #01b1ea 35%,
    #82318e 100%
  );
  --gradient-text-logo-inverted: -webkit-linear-gradient(
    left,
    #01b1ea 35%,
    #82318e 100%
  );

  --gradient-text-blue-blue: -webkit-linear-gradient(
    180deg,
    #50a1ff 0%,
    #66d2ea 100%
  );

  --gradient-text-blue-blue-inverted: -webkit-linear-gradient(
    0deg,
    #50a1ff 0%,
    #66d2ea 100%
  );

  --outline-size: 1px;
}
