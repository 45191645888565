@import '../../styles/responsive';

.ProfileDisplay {
  display: flex;
  flex-direction: column;
  &-profile-page {
    .ProfileDisplay {
      &-cover-image {
        order: 1;
      }
      &-info {
        order: 2;
      }
      &-kit {
        order: 3;
      }
    }
  }
  &-info {
    order: 1;
  }
  &-cover-image {
    order: 2;
  }
  &-kit {
    order: 3;
  }
  &-booked-out {
    opacity: 0.7;
    &-text {
      position: absolute;
      bottom: 15px;
      width: 100%;
      left: 0;
      text-align: center;
      font-size: 1.3rem;
      color: white;
      font-family: var(--font-secondary);
    }
  }
  // box-shadow: 0 -2px 2px 0px rgba(0, 0, 0, 0.03);
  &-cover-image {
    position: relative;
  }
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 60%, rgba(black, 0.8));
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 2rem;
      color: white;
      background: rgba(white, 0.3);
      margin-right: 2rem;
      font-size: 14px;
      padding: 0.6rem 0.8rem;
      border: none;
      box-shadow: 0 0 2px 2px rgba(black, 0.04);
      border-radius: 4px;
    }
  }

  @include desktop() {
    margin-bottom: 2em;
    &-cover-image {
      order: 1;
    }

    &-info {
      order: 2;
    }
    &-kit {
      order: 3;
      display: none;
    }
  }
}
