.ProfileFooter {
  &-cta {
    @media screen and (max-width: 321px) {
      button.check-availability {
        margin-left: -10px;
        padding: 8px 15px !important;
        span {
          font-size: 12px !important;
        }
      }
    }
  }
}
