// variables
@import './styles/variables';

// import bulma
@import '~bulma-scss/bulma';

// helpers
@import './styles/helpers';

@import '~react-phone-number-input/style.css';

@import './styles/form';

html,
body {
  font-family: var(--font-primary);
  color: var(--dark-75);
  width: 100%;
  height: 100%;
  font-size: 16px;
}

@for $heading-size from 1 through 6 {
  @include heading($heading-size);
}

a {
  color: var(--dark-75);
  &:visited {
    color: var(--dark-75);
  }
  &:hover,
  &:active,
  &.active {
    color: var(--purple);
  }
}

strong {
  color: inherit;
}

.navbar-brand {
  flex-grow: 1;
  justify-content: center;
  background: rgba(black, 0.03);
}

.navbar-item {
  font-family: var(--font-secondary);
  font-size: 1.5em;
  padding: 5px;
}

// react tag input
.react-tagsinput {
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 2px;
  &.has-error {
    border-color: var(--red);
  }
  &--focused {
    box-shadow: none;
    border-color: #484848 !important;
  }
  &-tag {
    background: var(--gradient-1);
    color: white !important;
    border-radius: 4px !important;
    border-color: white !important;
    a {
      color: inherit;
    }
  }
  &-input {
    font-size: 16px !important;
  }
}

input {
  font-size: 16px !important;
}

// modal
.modal {
  &-card {
    &-head,
    &-foot {
      background: white;
    }
    &-head {
      position: relative;
      .delete {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
    max-height: initial;
    &.is-fullscreen {
      .modal-card-head {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

.has-modal-open {
  overflow: hidden;
}

.logo {
  height: 1.3em;
  width: 1.3em;
  border-radius: 2em;
  // background: var(--gradient-logo-slanted);
  // color: white;
  font-family: var(--font-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: 'F';
    display: block;
  }
}

small {
  display: inline-block;
}

button {
  touch-action: manipulation;
}

.react-phone-number-input {
  &__row {
    position: relative;
    min-height: 40px;
  }
  &__country {
    z-index: 2;
    margin-left: 0.5rem;
  }
  &__icon {
    border: 0 !important;
    &-image {
      display: block;
    }
  }
  &__input {
    border: 1px solid !important;
    background-color: #fff;
    border-color: #dbdbdb !important;
    color: #363636;
    padding: 0.5rem !important;
    padding-left: calc(1.5em + 1.5rem) !important;
    height: auto !important;
    max-width: 100%;
    width: 100% !important;
    box-shadow: none;
    border-radius: 2px !important;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    &:focus {
      box-shadow: none;
      border-color: #484848 !important;
    }
    &::placeholder {
      font-size: 0.85em;
      opacity: 0.4;
    }
  }
}
.has-error {
  .react-phone-number-input {
    &__input {
      &:not(:focus) {
        border-color: var(--red) !important;
      }
    }
  }
}

// @include mobile {
// }

// @include tablet {
// }

// @include touch {
// }

// @include desktop {
// }

// @include widescreen {
// }

// @include fullhd {
// }
