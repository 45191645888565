.Tab {
  .tabs {
    top: 70px;
    ul {
      border-bottom: 0;
      position: relative;
      li {
        color: var(--dark-25);
        font-family: var(--font-secondary);
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          background: transparent;
          border-bottom: 1px solid var(--dark-25);
        }
        a {
          color: var(--dark-75);
          padding-left: 1.8rem;
          padding-right: 1.8rem;
          padding-bottom: 0.4rem;
        }
        &.is-active {
          a {
            font-weight: bold;
            border-bottom-color: transparent;
          }
          &:after {
            background: var(--gradient-2);
            border: none;
          }
        }
      }
    }
  }

  &-notification {
    position: absolute;
    top: -0.3em;
    right: -1.7em;
    color: white;
    background: var(--gradient-1);
    display: flex;
    width: 1.4em;
    height: 1.4em;
    font-size: 0.6rem;
    border-radius: 50%;
    align-content: center;
    justify-content: center;
    font-weight: 500;
  }
}
