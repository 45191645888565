@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../styles/responsive';

@mixin dots() {
  margin: 0;
  width: var(--slider-indicator-size);
  height: 3px;
  box-shadow: none;
  border-radius: 0;
  background-color: rgba(gray, 0.5);
}

.Carousel {
  &__container {
    @include mobile() {
      width: 100vw;
      height: 100vw;
    }

    width: 100%;
    height: 500px;
    background: var(--dark-5);
    .carousel-slider {
      padding-bottom: 30px;
      .slide {
        background: var(--dark-5);
      }
    }
    .control-dots {
      bottom: -5px;
      .dot {
        @include dots();
      }
    }
    .slick {
      &-slider {
        --arrow-size: 45px;
        --arrow-font-size: 2.5rem;
        --arrow-distance: 10px;
      }
      &-arrow {
        width: var(--arrow-size);
        height: var(--arrow-size);
        z-index: 1;
        &:before {
          font-size: var(--arrow-font-size);
          text-shadow: 0 0 3px rgba(black, 0.3);
          opacity: 0.9;
          color: #ececec;
        }
      }
      &-prev {
        left: var(--arrow-distance);
      }
      &-next {
        right: var(--arrow-distance);
      }
      &-dots {
        bottom: -12px;
        > li {
          margin: 0;
          height: 3px;
          width: var(--slider-indicator-size);
          button {
            height: 3px;
            width: var(--slider-indicator-size);
            padding: 0;
            background-color: rgba(gray, 0.5);
            border: 0;
            &:before {
              display: none;
            }
          }
          &.slick-active {
            button {
              background-color: gray;
            }
          }
        }
      }
    }
  }
  &__img-container {
    @include desktop {
      padding: 3rem;
    }
    @include mobile() {
      height: 100vw;
    }
    height: auto;
    position: relative;
    &:after {
      content: 'flayr';
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 500;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 0;
      font-size: 2em;
      background: var(--gradient-text-logo);
      background-size: 400% 400%;
      animation: animate-gradient 4s ease-out infinite alternate;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      position: relative;
      z-index: 1;
    }
  }

  &__count {
    text-shadow: 0 0 3px rgba(black, 0.3);
    opacity: 0.99;
    color: #ececec;
    text-align: right;
    margin-top: -40px;
    padding-right: 10px;
  }
}

@keyframes animate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
