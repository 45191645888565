.Switch {
  display: inline-block;
  --width: 45px;
  display: inline-flex;
  width: var(--width);
  height: calc(var(--width) / 2);
  position: relative;
  border-radius: calc(var(--width) / 2);
  cursor: pointer;
  transition: all 0.3s ease;
  &-disabled {
    opacity: 0.5;
  }

  &-checkbox {
    width: 0;
    height: 0;
    appearance: none;
    opacity: 0;
    & {
      + span {
        background: var(--dark-50);
        display: inline-block;
        width: 100%;
        border-radius: inherit;
        &:before {
          content: '';
          position: absolute;
          height: calc(100% - 2px);
          width: calc(100% - 2px);
          border-radius: 20px;
          top: 1px;
          left: 1px;
        }
        &:after {
          content: '';
          position: absolute;
          height: 15px;
          width: 15px;
          background: white;
          top: 50%;
          border-radius: 50%;
          transform: translate(5px, -50%);
          transition: all 0.3s ease;
        }
      }
      &:checked {
        + span {
          border: 1px solid var(--green);
          background: var(--gradient-2);
          &:after {
            transform: translate(26px, -50%);
          }
        }
      }
    }
  }
}
