.ScrollPanel {
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* fixes scrolling in IE */
  padding-left: 0;
  padding-right: 0;

  &-frame-wrapper {
    overflow: hidden;
    overflow-x: scroll;
  }
  &-frame {
    overflow: visible;
    margin-bottom: 0;
    white-space: nowrap;
  }
  &-panel {
    // max-width: 270px;
    /* width of each individual items */
    vertical-align: top;
    // margin-right: 40px;
    white-space: normal;
  }
}
