// background
@mixin bg($variable, $color) {
  .has-bg-#{$variable} {
    background: var(--#{$variable});
    color: $color;
  }
}

// gradient font
@mixin gradient-font($webkit-gradient, $default-color) {
  color: $default-color;
  background: $webkit-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// gradient button
@mixin gradient-button($number, $var) {
  &.is-gradient {
    &-#{$number} {
      &:not(.is-small) {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }
      border: none;
      padding-right: 2rem;
      padding-left: 2rem;
      color: white;
      background: $var;
      font-size: 14px;
      font-weight: bold;
      height: auto;
      &.is-rounded {
        padding-right: 2rem;
        padding-left: 2rem;
      }
      &.is-circle {
        --size: 45px;
        height: var(--size);
        width: var(--size);
        border-radius: 50%;
        padding: 0;
      }
      &:active,
      &:hover {
        color: white;
      }
      &.is-outlined {
        background: white;
        color: var(--gradient-#{$number}-default);
        position: relative;
        overflow: hidden;
        padding: var(--outline-size);
        > span {
          background: white;
          display: inline-block;
          width: 100%;
          z-index: 2;
          border-radius: inherit;
          padding: 0.4rem 1.3rem;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: $var;
          border-radius: inherit;
        }
      }
      &.is-circle {
        display: inline-flex;
        align-items: stretch;
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          top: 2px;
          left: 2px;
          padding: 0;
          position: absolute;
        }
      }
    }
  }
}

@mixin gradient-file($number) {
  &.is-gradient {
    &-#{$number} {
      .file-cta {
        color: white;
        background: var(--gradient-#{$number});
        border: none;
      }
    }
  }
}

@mixin heading($size) {
  h#{$size} {
    font-weight: 700;
    font-size: 3.75rem / $size;
    font-family: var(--font-secondary);
    color: var(--dark-100);
    line-height: 1em;
  }
}

@mixin input-custom() {
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 2px;
  &:focus {
    &:not(.is-danger) {
      box-shadow: none;
      border-color: #484848;
    }
  }
  &.has-height-auto {
    height: auto;
  }
}
