@mixin icon() {
  height: 1em;
  width: 1em;
  display: inline-block;
}

.is-icon {
  &-chevron {
    &-right {
      @include icon();
      position: relative;
      &:before,
      &:after {
        height: 0.1em;
        width: 1em;
        background: currentColor;
        content: '';
        transform: rotate(45deg);
        display: inline-block;
        transform-origin: right;
        position: absolute;
        top: 0.5em;
        left: 0;
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
