.ProgressTracker {
  --progress-bg: var(--gradient-1);
  width: 100%;
  min-height: 24px;
  position: relative;
  &-progressbar {
    display: block;
    height: 4px;
    background: var(--progress-bg);
    position: absolute;
    top: calc(50% - 2px);
    animation: animate-progress 5s ease-out forwards;
  }
  &-item {
    &-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      &-title {
        position: absolute;
        bottom: -20px;
        width: 100%;
      }
      &-icon {
        background: white;
        .ProgressTracker-item {
          &:nth-of-type(1) {
            .ProgressTracker-item-icon {
              animation: animate-icon 0.3s 0.8s ease-in forwards;
            }
          }
          &:nth-of-type(2) {
            .ProgressTracker-item-icon {
              animation: animate-icon 0.3s 2.8s ease-in forwards;
            }
          }
        }
      }
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.8em;
      height: 1.8em;
      position: relative;
      z-index: 1;
      background: white;
      font-size: 1.2em;
      border-radius: 50%;
    }
  }
}

@keyframes animate-progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes animate-icon {
  0% {
    transform: none;
    background: var(--progress-bg);
    color: white;
  }
  50% {
    transform: scale(1.3);
    background: var(--progress-bg);
    color: white;
  }
  100% {
    transform: none;
    background: var(--progress-bg);
    color: white;
  }
}
