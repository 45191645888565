.QuoteForm {
  &-input-holder {
    position: relative;
    input {
      &:focus,
      &:not(:placeholder-shown) {
        ~ label {
          opacity: 0;
          z-index: -1;
        }
      }
    }
    label {
      z-index: 2;
      display: block;
      position: absolute;
      top: 0.6em;
      font-size: 14px;
      pointer-events: none;
    }
  }
  button {
    min-height: 36px;
  }
}
