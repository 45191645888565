@import '../../styles/responsive';

.SearchResult {
  @include mobile {
    padding-top: 48px;
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-filter-group {
      order: 1;
    }
    &-quote-form {
      order: 2;
    }
    &-list {
      order: 3;
    }
    &-help {
      order: 4;
    }
    &-jobs {
      order: 5;
    }
  }
  .tabs {
    position: sticky;
    top: calc(47px);
    z-index: 3;
    padding-bottom: 4px;
  }

  &-quote-form {
    background: var(--gradient-1);
  }

  @include tablet {
    &-container {
      display: grid;
      grid-template-columns: 300px calc(100vw - 300px);

      grid-template-areas: 'sidebar list';
      // grid-gap: 15px;
      @media screen and (min-width: 2100px) {
        grid-template-columns: 430px auto;
      }
    }
    .tabs {
      top: 110px;
      padding-bottom: 4px;
    }
    .aside {
      grid-area: sidebar;
    }
    &-reasons {
      position: sticky;
      top: calc(49px + 150px);
    }
    &-filter-group {
      grid-area: list;
      position: sticky;
      top: 47px;
      background: white;
      z-index: 3;
      @media screen and (min-width: 2100px) {
        max-width: calc(100vw - 455px);
      }
    }
    &-list {
      grid-area: list;
      padding: 0 15px;
    }
    &-quote-form {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      position: sticky;
      top: 49px;
      z-index: 5;
    }
    &-help {
      position: sticky;
      top: calc(49px + 150px + 265px);
    }
    &-item {
      .provider-card-container {
        @include tablet() {
          display: grid;
          grid-template-columns: repeat(2, minmax(240px, 1fr));
          justify-content: center;
          grid-gap: 20px;
          @include desktop() {
            grid-template-columns: repeat(2, 300px);
          }
          @include widescreen() {
            grid-template-columns: repeat(3, 300px);
          }
          @media screen and (min-width: 2100px) {
            padding: 0 1.5em;
            grid-template-columns: repeat(4, minmax(300px, 500px));
            .ProfileDisplay {
              padding-left: 3em;
              padding-right: 3em;
            }
            max-width: calc(100vw - 455px);
          }
        }
      }
    }
    .sidebar {
      position: sticky;
      top: 49px;
      height: calc(100vh - 50px);
      // overflow-y: auto;
      // padding-bottom: 15px;
      z-index: 6;
    }
  }
}
