.LocationInput {
  position: relative;
  .delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    display: block;
  }
}

.pac-item {
  padding: 8px 15px;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.pac-container {
  font-family: var(--font-primary);
}
