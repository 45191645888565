.Accordion {
  border-bottom: 1px solid var(--dark-5);

  > label {
    display: flex;
    flex-direction: column;
  }
  &-toggle {
    height: 0;
    width: 0;
    appearance: none;
    opacity: 0;
    & ~ .Accordion-heading {
      border-top: 1px solid var(--dark-5);
      .Accordion-indicator {
        transform: none;
        transition: transform 0.2s;
      }
    }
    & ~ .Accordion-content {
      display: none;
      // height: 0;
      // transform: scaleY(0);
      // transform-origin: top;
      // transition: transform 0.1s;
      // padding: 0 !important;
    }
    &:checked {
      & ~ .Accordion-content {
        display: block;
        // height: auto;
        // transform: scaleY(1);
      }
      & ~ .Accordion-heading {
        .Accordion-indicator {
          transform: rotate(180deg);
          transition: transform 0.2s;
        }
      }
    }
  }
}
