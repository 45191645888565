.CalendarBody {
  &__row {
    display: flex;
  }
  &__column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__date {
    &-container {
      position: relative;
      border-radius: 50%;
      background: transparent;
      border: none;
      overflow: hidden;
      padding: 0;
      margin: 2px;
      cursor: pointer;
      &--is-booked-out {
        background: var(--gradient-4);
        color: white;
        font-weight: bold;
      }
      &--is-partially-booked {
        background: var(--gradient-4-light);
      }
      &--is-selected {
        background: var(--gradient-2);
        color: white;
        font-weight: bold;
      }
      &:before {
        padding-bottom: 100%;
        content: '';
        display: block;
      }
    }
    &-item {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
