.CalendarNav {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav-btn {
    --size: 45px;
    border: none;
    height: var(--size);
    width: var(--size);
    color: var(--purple);
    background: none;
    font-size: 1.3rem;
  }
}