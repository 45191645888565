// button
.button {
  @include gradient-button(1, var(--gradient-1));
  @include gradient-button(2, var(--gradient-2));
  @include gradient-button(3, var(--gradient-3));
  @include gradient-button(4, var(--gradient-4));
  @include gradient-button(dark, var(--gradient-dark));
  @include gradient-button(logo, var(--gradient-logo));
  @include gradient-button(blue-blue, var(--gradient-blue-blue));
  @include gradient-button(
    blue-blue-inverted,
    var(--gradient-blue-blue-inverted)
  );
  &.is-success {
    color: white;
    &:hover {
      color: white;
    }
    &.is-outlined {
      &:active,
      &:focus {
        color: white;
      }
      &:hover {
        color: white;
      }
    }
  }

  &.has-icon {
    position: relative;
    padding-right: 35px;
    .icon {
      position: absolute;
      right: 20px;
      font-size: 1.3em;
    }
  }

  &.is-clear {
    border: none;
    background: none;
  }

  &.is-translucent {
    &-white {
      border: none;
      background: rgba(white, 0.3);
      color: white;
      &:hover,
      &:active {
        color: white;
        background: rgba(white, 0.1);
      }
    }
  }
  &.has-height-auto {
    height: auto;
  }
}

// file
.file {
  @include gradient-file(1);
  @include gradient-file(2);
  &.is-rounded {
    .file-cta {
      border-radius: 2em;
      padding: 1.2rem 2rem;
    }
  }
  &.is-clear {
    .file-cta {
      border: none;
      background: transparent;
    }
  }
}
