@import './mixins';
@import './_icons';

@import './text';
@import './background';
@import './button';
@import './spacing';

// border
.has-border {
  &-color {
    &-100 {
      border-color: var(--dark-100);
      &.loader {
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
    &-75 {
      border-color: var(--dark-75);
      &.loader {
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }
  &-100 {
    border: 1px solid var(--dark-100);
  }
  &-75 {
    border: 1px solid var(--dark-75);
  }
  &-50 {
    border: 1px solid var(--dark-50);
  }
  &-25 {
    border: 1px solid var(--dark-25);
  }
  &-5 {
    border: 1px solid var(--dark-5);
  }
  &-top {
    &-100 {
      border-top: 1px solid var(--dark-100);
    }
    &-75 {
      border-top: 1px solid var(--dark-75);
    }
    &-50 {
      border-top: 1px solid var(--dark-50);
    }
    &-25 {
      border-top: 1px solid var(--dark-25);
    }
    &-5 {
      border-top: 1px solid var(--dark-5);
    }
  }

  &-bottom {
    &-100 {
      border-bottom: 1px solid var(--dark-100);
    }
    &-75 {
      border-bottom: 1px solid var(--dark-75);
    }
    &-50 {
      border-bottom: 1px solid var(--dark-50);
    }
    &-25 {
      border-bottom: 1px solid var(--dark-25);
    }
    &-5 {
      border-bottom: 1px solid var(--dark-5);
    }
    &-white {
      border-bottom: 1px solid white;
    }
  }

  &-right {
    &-100 {
      border-right: 1px solid var(--dark-100);
    }
    &-75 {
      border-right: 1px solid var(--dark-75);
    }
    &-50 {
      border-right: 1px solid var(--dark-50);
    }
    &-25 {
      border-right: 1px solid var(--dark-25);
    }
    &-5 {
      border-right: 1px solid var(--dark-5);
    }
  }
  &-white {
    border: 1px solid white;
  }
  &-25 {
    border: 1px solid var(--dark-25);
  }
  &-red,
  &-danger {
    border: 1px solid var(--red);
  }
}

// shadow
.has-shadow {
  $color: rgba(0, 0, 0, 0.04);
  &-1 {
    box-shadow: 0 0 2px 2px $color;
  }

  &-top {
    box-shadow: 0px -1px 2px 2px $color;
  }
  &-bottom {
    box-shadow: 0px 1px 2px 2px $color;
  }
}

// cursor
.has-pointer {
  cursor: pointer;
}

// image
.image {
  &.has-covering-img {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &.is {
    &-56x56 {
      width: 56px;
      height: 56px;
    }
    &-grayscale {
      img {
        filter: url('/img/grayscale.svg#grayscale');
      }
    }
  }
  .is-cropped-top {
    object-position: top;
  }
  .is-rounded {
    height: 100%;
  }

  &.has-overlay {
    &-bottom {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          transparent 60%,
          rgba(0, 0, 0, 0.8)
        );
      }
    }
  }
}

// flex
.is {
  &-flex {
    &.is-column {
      flex-direction: column;
    }
    &.is-fullwidth {
      width: 100%;
    }
    &.is-no-wrap {
      flex-wrap: none;
    }
  }
  &-vcentered {
    align-items: center;
  }
  &-hcentered {
    justify-content: center;
  }
  &-vend {
    align-items: flex-end;
  }
  &-flex-fill {
    flex: 1;
  }
}

.columns {
  &.is-vend {
    align-items: flex-end;
  }
}

// size
.is {
  &-fill-x {
    width: 100%;
  }
  &-fill-y {
    height: 100%;
  }
  &-fullscreen {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &-max {
    &-width {
      &-50 {
        max-width: 50%;
      }
    }
  }
}

// overflow
.has-scroll {
  &-y {
    overflow-y: auto;
    &-hidden {
      overflow-y: hidden;
    }
  }
  &-x {
    overflow-x: auto;
    &-hidden {
      overflow-x: hidden;
    }
  }
}
// scrollbar
.has-scrollbar-hidden {
  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

// positioning
.is-fixed {
  position: fixed;
  &-top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
  }
}
.is-sticky {
  &-top {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &-bottom {
    position: sticky;
    bottom: 0;
    z-index: 5;
  }
}
.is-relative {
  position: relative;
}
// z-index
.is {
  &-elevated {
    &-1 {
      z-index: 10;
    }
    &-2 {
      z-index: 20;
    }
  }
}

// transitions
.is-slideable {
  transition: transform 0.3s ease-in;
  &.slide {
    &-out-bottom {
      transform: translateY(200%);
    }
  }
}

// line height
.has {
  &-line-height {
    @for $i from 1 through 8 {
      &-#{$i} {
        line-height: 0.7 + $i * 0.1em;
      }
    }
  }
}

// star
.star {
  &:before {
    content: '\2605';
  }
  &-outlined {
    &:before {
      content: '☆';
    }
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 3px solid rgb(97, 95, 95);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(97, 95, 95) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
