.Collage {
  $img-height: 460px;
  position: relative;

  &-container {
    display: grid;
    grid-gap: 8px;
    height: $img-height;
    justify-content: center;

    &[data-count='6'] {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      .Collage-item {
        &:first-child,
        &:last-child {
          grid-row-start: 1;
          grid-row-end: 3;
        }
      }
    }

    &[data-count='5'] {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      .Collage-item {
        &:first-child,
        &:last-child {
          grid-row-start: 1;
          grid-row-end: 3;
        }
      }
    }

    &[data-count='4'] {
      grid-template-columns: repeat(3, minmax(auto, $img-height));
      grid-template-rows: repeat(2, minmax(auto, $img-height / 2));
      .Collage-item {
        &:first-child,
        &:last-child {
          grid-row-start: 1;
          grid-row-end: 3;
        }
        &:last-child {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    text-shadow: 1px 1px rgba(black, 0.03);
    width: 100%;
    left: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 60px;
    pointer-events: none;
  }

  &-show-all {
    margin-top: -2.1rem;
    transform: translateY(-0.8rem);
    button {
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    &-img-container {
      max-width: 32%;
      min-width: 360px;
      text-align: center;
    }
    &-count {
      position: absolute;
      bottom: -2rem;
    }
    .delete {
      $size: 48px;
      position: fixed;
      top: 25px;
      right: 35px;
      max-height: $size;
      max-width: $size;
      min-height: $size;
      min-width: $size;
      width: $size;
    }
    .nav-icon {
      font-size: 3rem;
      &:first-of-type {
        transform: translateX(-50px);
      }
      &:last-of-type {
        transform: translateX(50px);
      }
    }
    &-cover {
      background: rgba(black, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    &-thumbnails {
      width: 100%;
      position: absolute;
      bottom: 15px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      // justify-content: center;
      height: 96px;
      padding: 0 15px;
      .image {
        min-width: 96px;
        cursor: pointer;
      }
    }
  }
}
